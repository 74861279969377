<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="12" md="7">
        <h2 class="header font-weight-bold mt-5 white--text text-center">
          CANAL PNDS TV
        </h2>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-0 pt-0">
      <v-col cols="12" md="12">
        <LazyYoutube
          ref="lazyVideo"
          maxWidth="1400"
          :src="videoId"
          :showTitle="true"
        />
      </v-col>
      <!-- <v-col cols="12" md="4">
        <v-container class="videos-list">
          <v-row dense>
            <v-col v-for="(video, index) in videos" :key="index" cols="6">
              <v-img
                :src="video.snippet.thumbnails.medium.url"
                @click.stop="changeVideo(video.id.videoId)"
                class="video-thumb"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-col> -->
    </v-row>

    <v-spacer></v-spacer>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
// import ChannelVideos from "@/api/videos";

export default {
  name: "CanalTV",

  data: () => ({
    // logged: false
    // channelUrl: "https://www.youtube.com/channel/UCRvsl5sv-GTpcYtchptHOqA",
    videoId: "https://youtu.be/dIh5O3Nnv54",
    videos: []
  }),

  components: {
    LazyYoutube
  }

  // created() {
  //   this.getVideos();
  // },

  // methods: {
  //   handleClick(event) {
  //     this.$refs["lazyVideo"][event]();
  //   },
  //   async getVideos() {
  //     await ChannelVideos.getChannelVideos()
  //       .then(response => {
  //         this.videos = response.data.items;
  //         console.log("S'han obtingut els videos");
  //       })
  //       .catch(error => {
  //         // this.alertMsg = "Oops! S'ha produït un error inesperat.";
  //         // this.errorAlert();
  //         console.log(error);
  //       });
  //   },
  //   changeVideo(id) {
  //     this.videoId = "https://youtu.be/" + id;
  //   }
  // }
};
</script>

<style scoped>
.video-thumb {
  cursor: pointer;
  border: 1px solid #e2e2e2;
  /* border-radius: 5px; */
}
.video-thumb:hover {
  /* cursor: pointer; */
  border: 1px solid #ffad15;
  /* border-radius: 5px; */
}
.videos-list {
  overflow-y: auto;
  height: 430px;
}
</style>
